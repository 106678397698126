.app-header {
  align-items: flex-end;
}

.app-header h1 {
  flex-grow: 1;
}

.app-header h2 {
  font-size: 1.2rem;
  color: grey;
}