.todo-list-item {
  font-size: 1.25rem;
}

.todo-list-item button {
  width: 35px;
  margin: 3px;
}

.todo-list-item-label {
  margin-left: 1.25rem;
  line-height: 35px;
  cursor: pointer;
  user-select: none;
}

.todo-list-item.done .todo-list-item-label {
  text-decoration: line-through;
}

.todo-list-item.important .todo-list-item-label {
  font-weight: bold;
  color: steelblue;
}
